<div>

  <mat-tab-group #tabGroup="matTabGroup">
    <mat-tab label="Eingang">
      <app-documents-list>
        <button
          mat-button
          color="primary"
          (click)="tabGroup.selectedIndex=2">
          Dokumente an Kanzlei senden
        </button>
      </app-documents-list>
    </mat-tab>
    <mat-tab label="Ausgang">
      <app-upload-documents></app-upload-documents>
    </mat-tab>
  </mat-tab-group>

</div>
