import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, forkJoin, mergeMap, Observable, of, Subject, switchMap, take, tap} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {RequestManagerService} from "./request-manager.service";


import {DMSDocument} from "./data-interfaces";

@Injectable({
  providedIn: 'root'
})
export class DocumentPageService {

  private documentLoading: any = [];
  private documentsDownloaded = new Subject<boolean>();

  private clientNumberSource = new BehaviorSubject<string | null>(null);
  private employeeNameSource = new BehaviorSubject<number | null>(null);
  private documentSource = new BehaviorSubject<DMSDocument[]>([]);
  // New Subjects and Observables for tab switching
  private tabSwitchSource = new BehaviorSubject<number>(0);
  tabSwitch$ = this.tabSwitchSource.asObservable();

  clientNumber$ = this.clientNumberSource.asObservable();
  employeeName$ = this.employeeNameSource.asObservable();
  documentsToSign$ = this.documentSource.asObservable();

  constructor(private requestManager: RequestManagerService) {
  }

  getDocumentsDownloaded(): Observable<boolean> {
    return this.documentsDownloaded.asObservable();
  }

  downloadDocuments(documentsToDownload: DMSDocument): Observable<any> {

    return this.requestManager.getForInstance('dms/document/' + documentsToDownload.id + '/download', {}, {}, 'blob').pipe(
      take(1),
      tap((documentBlob) => {

        let data = documentBlob as HttpResponse<Blob>;
        const file = new Blob([documentBlob], {type: data.body?.type});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);

        link.download = this.getDocumentName(documentsToDownload);

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);

        }),
        mergeMap(() => {
          return this.requestManager.put('dms/document/' + documentsToDownload.id + '/downloaded', {})
            .pipe(
              tap(() => {
                this.documentLoading.splice(this.documentLoading.indexOf(documentsToDownload.id));
              })
            );
        }),
      catchError((error) => {
        console.log(error);
        this.documentLoading.splice(this.documentLoading.indexOf(documentsToDownload.id));
        return of(null);
      })
    );
  }

  public getDocumentName(documentToDownload: DMSDocument, sign = false): string {
    const extension = '.' + documentToDownload.extension.toLowerCase().replace(';', '.');

    const description = (
      documentToDownload.description ?
        documentToDownload.description.replace(/[^a-zA-Z0-9._:\- ]/g, '')
        : 'Dokument'
    );

    return !description.endsWith(extension) ?
      ((sign ? (description + ' signiert') : description) + extension)
      : (sign ? description.replace(extension, ' signiert' + extension) : description);

  }


  setClientNumber(clientNumber: string) {
    this.clientNumberSource.next(clientNumber);
  }

  setEmployeeNumber(employeeName: number) {
    this.employeeNameSource.next(employeeName);
  }

  setDocuments(datevDocuments: DMSDocument[]) {
    this.documentSource.next(datevDocuments);
  }


  switchToTab(index: number) {
    this.tabSwitchSource.next(index);
  }
}
