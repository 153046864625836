<mat-card>
  <mat-card-header>
    <mat-card-title>
      Allgemeine Einstellungen
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="form" [formGroup]="generalSettingsForm">
      <mat-slide-toggle formControlName="tabOrderActive">Menü-Tab "Aufträge" sichtbar
      </mat-slide-toggle>
      <mat-slide-toggle formControlName="showAmountsInOrderTab">€-Beträge im Menü-Tab "Aufträge" sichtbar
      </mat-slide-toggle>
      <mat-form-field appearance="fill" class="mat-form-field-text-input">
        <mat-label>Datenschutzmodus</mat-label>
        <mat-select formControlName="dataProtectionModeActive">
          <mat-option [value]="'1'">Extrem</mat-option>
          <mat-option [value]="'0'">Komfort</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="mat-form-field-text-input">
        <mat-label>Sekretariat - Email</mat-label>
        <input
          matInput
          formControlName="secretaryEmail"/>
        <mat-error *ngIf="generalSettingsForm.get('secretaryEmail')?.hasError('required')">
          Muss ausgefüllt werden
        </mat-error>
        <mat-error *ngIf="generalSettingsForm.get('secretaryEmail')?.hasError('invalidEmail')">
          Ungültige E-Mail-Adresse
        </mat-error>
      </mat-form-field>
    </div>


    <div class="block-color-settings">
      <div class="form-field-color">
        <label>Farbe 1 <br/><br/></label>
        <input [(colorPicker)]="primaryColor" [style.background]="primaryColor" class="color-picker-input">
      </div>

      <div class="form-field-color">
        <label>Farbe 2 <br/><br/></label>
        <input
          [(colorPicker)]="secondaryColor"
          [style.background]="secondaryColor" class="color-picker-input">
      </div>

      <button
        mat-raised-button
        class="button-submit-colors"
        color="primary"
        (click)="submitColors()">
        Farben anwenden
      </button>
    </div>
  </mat-card-content>
  <mat-card-footer>

  </mat-card-footer>

</mat-card>



