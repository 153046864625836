import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, tap, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {RequestManagerService} from '../../../services/request-manager.service';
import {NotifierService} from 'angular-notifier';
import {DocumentFilterService} from '../../../services/document-filter.service';
import {NgxFileDropEntry} from 'ngx-file-drop';
import {DocumentPageService} from "../../../services/document-page.service";
import {Client, DMSDocument} from "../../../services/data-interfaces";

interface Employee {
  id: number | string;
  display_name: string;
  status: string;
}

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.css']
})
export class UploadDocumentsComponent implements OnInit {

  loadingSubmit$ = new BehaviorSubject(false);
  loadingEmployees$ = new BehaviorSubject(false);

  fileForm = this.fb.group({
    files: this.fb.control('', [Validators.required]),
    fromClientId: this.fb.control('', [Validators.required]),
    toEmployeeId: this.fb.control('', [Validators.required]),
    filesToSign: this.fb.control(false, [Validators.required]),
    note: this.fb.control(''),
    answerRequested: this.fb.control(false)
  });

  filesToUpload: { name: string, content: string }[] = [];

  uploadAvailable$: Observable<boolean>;
  userClients$: Observable<any>;
  filteredEmployees: Employee[] = [];
  allEmployees: Employee[] = [];
  signMode = false;
  secretaryEmail: string | null = null;
  companyName: string | null = null;

  constructor(
    private requestManager: RequestManagerService,
    private notifier: NotifierService,
    private filterService: DocumentFilterService,
    private fb: FormBuilder,
    private documentService: DocumentPageService
  ) {


    this.requestManager.get('general/settings/')
      .pipe(
      ).subscribe((settings) => {
      this.secretaryEmail = settings.secretaryEmail
      this.companyName = settings.companyName;
    });


    this.documentService.clientNumber$
      .pipe(
        switchMap((clientNumber: string | null) => {
          if (clientNumber) {
            return this.requestManager.get('clients?clientNumber=' + clientNumber);
          }
          return of(null);
        }),
        tap((client: Client) => {
          if (client) {
            this.fileForm.get('fromClientId')?.patchValue(client.id);
          }
        })
      )
      .subscribe(() => {
      }, () => {
      });


    this.documentService.employeeName$
      .pipe(
        switchMap((employeeNumber: number | null) => {
          if (employeeNumber) {
            return this.requestManager.get('employees?employeeNumber=' + employeeNumber);
          }
          return of(null);
        }),
        tap((user: any) => {
          console.log(user);
          if (user) {
            this.fileForm.get('toEmployeeId')?.patchValue(user.id);
          }
        })
      )
      .subscribe(() => {
      }, () => {
      });


    this.documentService.documentsToSign$
      .pipe(
        tap((documents: DMSDocument[]) => {
          console.log(documents);
          if (documents.length > 0) {
            this.signMode = true;
            let text = "Hiermit bestätige ich das Dokument im Anhang";
            if (documents.length > 1) {
              text = "Hiermit bestätige ich die Dokumente im Anhang";
            }
            this.fileForm.get('filesToSign')?.patchValue(true);
            this.fileForm.get('note')?.patchValue(text);

            for (let document of documents) {

              this.filesToUpload.push({
                name: this.documentService.getDocumentName(document, true),
                content: document.id
              });
              this.fileForm.get('files')?.patchValue(this.filesToUpload);
            }
          }
        }),
        tap((user: any) => {

        })
      )
      .subscribe(() => {
      }, () => {
      });

    this.uploadAvailable$ = this.requestManager.getForInstance('/dms/upload/available')
      .pipe(
        map((response: any) => response.available || false)
      );

    this.userClients$ = this.requestManager.getForInstance('user/self')
      .pipe(
        switchMap(userData => {
          if (userData.id) {
            return this.requestManager.getForInstance(`users/${userData.id}/userClients`)
              .pipe(
                map(userClients => {
                  const clients = [];
                  for (let userClient of userClients) {
                    if (userClient.client && Array.isArray(userClient.client) && userClient.client.length === 1) {
                      for (let client of userClient.client) {
                        clients.push(client);
                      }
                    }
                  }
                  return Object.values(clients);
                }),
                tap(clients => {
                  if (clients && Array.isArray(clients) && clients.length === 1) {
                    this.fileForm.get('fromClientId')?.patchValue(clients[0].id);
                  }
                }),
                catchError(error => {
                  this.notifier.notify('error', 'Es gab einen Fehler beim Laden der verfügbaren Mandanten!');
                  return throwError(error);
                })
              );
          }
          return of([]);
        })
      );
  }

  ngOnInit(): void {
    this.fileForm.get('fromClientId')!.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(() => this.loadingEmployees$.next(true)),
        switchMap(clientId => this.loadEmployees(clientId)),
        tap((employees: Employee[]) => {

          if (employees && Array.isArray(employees)) {
            this.allEmployees = employees.filter(employee => employee.status !== 'inactive');
            this.filteredEmployees = [...this.allEmployees]; // Kopiere das gefilterte Array

            if (this.secretaryEmail) {
              const secretaryEmployee = {
                id: "secretary",
                display_name: this.companyName ?? "Sekretariat",
                status: "active"
              };

              // Füge den Sekretär an den Anfang des Arrays hinzu
              this.filteredEmployees.unshift(secretaryEmployee);

              this.fileForm.get('toEmployeeId')?.patchValue("secretary");
            } else {
              if (this.allEmployees.length === 1) {
                this.fileForm.get('toEmployeeId')?.patchValue(this.allEmployees[0].id);
              }
            }
          }
          this.loadingEmployees$.next(false);
        }),
        catchError(error => {
          this.loadingEmployees$.next(false);
          this.notifier.notify('error', 'Es gab einen Fehler beim Laden der verfügbaren Mitarbeiter!');
          return throwError(error);
        })
      ).subscribe();
  }

  loadEmployees(clientId: string): Observable<Employee[]> {
    if (clientId) {
      return this.requestManager.getForInstance(`employees?clientId=${clientId}`);
    }
    return of([]);
  }

  filterEmployees(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value.toLowerCase();

    // Filtere die allEmployees Liste
    this.filteredEmployees = this.allEmployees.filter(employee =>
      employee.display_name.toLowerCase().includes(searchTerm)
    );

    // Überprüfen, ob "secretary" bereits in der gefilterten Liste ist
    const secretaryEmployee = {
      id: "secretary",
      display_name: this.companyName ?? "Sekretariat",
      status: "active"
    };

    const secretaryExists = this.filteredEmployees.some(employee => employee.id === 'secretary');

    // Füge "secretary" hinzu, falls er nicht bereits vorhanden ist
    if (!secretaryExists) {
      this.filteredEmployees.unshift(secretaryEmployee);
    }
  }


  submitNewFile() {
    this.loadingSubmit$.next(true);
    const body = this.fileForm.value;
    this.requestManager.post('dms/files/upload', body).subscribe(
      () => {
        this.loadingSubmit$.next(false);
        this.fileForm.reset();
        this.signMode = false;
        this.filesToUpload = [];
        this.notifier.notify('success', 'Dokument(e) erfolgreich hochgeladen!');
      },
      () => {
        this.notifier.notify('error', 'Es gab einen Fehler beim Hochladen der Dokumente! Melden Sie Ihr Problem bei support@riecken.io!');
        this.loadingSubmit$.next(false);
      }
    );
  }

  resetForm() {
    this.fileForm.reset();
    this.signMode = false;
    this.filesToUpload = [];
    this.filteredEmployees = this.allEmployees;
  }

  dropped(droppedFiles: NgxFileDropEntry[]) {
    for (let droppedFile of droppedFiles) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            let fileBase64Encoded = reader.result as string;
            fileBase64Encoded = fileBase64Encoded.split(';base64,', 2)[1];
            this.filesToUpload.push({name: file.name, content: fileBase64Encoded});
            this.fileForm.get('files')?.patchValue(this.filesToUpload);
          };
        });
      }
    }
  }

  deleteFromUploadList(file: { name: string; content: string }) {
    const index = this.filesToUpload.indexOf(file);
    if (index !== -1) {
      this.filesToUpload.splice(index, 1);
      this.fileForm.get('files')?.patchValue(this.filesToUpload);
    }
  }
}
