<mat-card *ngIf="(uploadAvailable$ | async) === true">
  <mat-card-header>
    <mat-card-title>
      <span>Nachricht / Dokument an die Kanzlei übermitteln</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content [formGroup]="fileForm">
    <div class="card-content">
      <div class="content-first-line">
        <div>
          <mat-form-field appearance="fill" class="select-from-client">
            <mat-label>Von</mat-label>
            <ng-container *ngIf="(userClients$ | async) as userClients">
              <mat-select formControlName="fromClientId" [disabled]="userClients.length === 1">
                <mat-option *ngFor="let client of userClients" [value]="client.id">
                  {{ client.clientNumber + ': ' + client.name }}
                </mat-option>
              </mat-select>
            </ng-container>
          </mat-form-field>
        </div>
        <div [hidden]="!fileForm.get('fromClientId')?.value">
          <mat-form-field appearance="fill" class="select-to-employee">
            <mat-label>An</mat-label>
            <ng-container *ngIf="(loadingEmployees$ | async) === true">
              <div class="skeleton"></div>
            </ng-container>
            <mat-select formControlName="toEmployeeId"
                        [disabled]="allEmployees.length === 1 || (loadingEmployees$ | async) === true">
              <ng-container *ngIf="allEmployees.length > 5">
                <mat-option class="search-option">
                  <input matInput (input)="filterEmployees($event)" (click)="$event.stopPropagation()"
                         placeholder="Suche Mitarbeiter">
                </mat-option>
              </ng-container>
              <ng-container *ngIf="filteredEmployees.length > 0">
                <mat-option *ngFor="let employee of filteredEmployees" [value]="employee.id">
                  {{ employee.display_name }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="content-second-line">
        <div>
          <mat-form-field appearance="fill" class="form-field-textarea">
            <mat-label>Notiz an Kanzlei</mat-label>
            <textarea matInput
                      [attr.disabled]="signMode"
                      [ngStyle]="{'resize': signMode ? 'none' : 'vertical'}"
                      placeholder="Geben Sie Ihre Beschreibung ein" formControlName="note"></textarea>
          </mat-form-field>
        </div>
        <div class="content-second-line-second-column">
          <ngx-file-drop
            [disabled]="signMode"
            [multiple]="false"
            (onFileDrop)="dropped($event)"
            dropZoneLabel="Dateien wählen"
            class="file-dropzone">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <button type="button" [disabled]="signMode" (click)="openFileSelector()" mat-button color="primary">Füge
                Dateien hinzu
              </button>
            </ng-template>
          </ngx-file-drop>
          <div *ngIf="!signMode">
            <mat-slide-toggle formControlName="answerRequested">Rückmeldung erwünscht</mat-slide-toggle>
          </div>
        </div>
      </div>


      <div class="block-files-to-upload" *ngIf="filesToUpload.length > 0">
        <h3>Dokumente zum Upload: </h3>
        <div *ngFor="let file of filesToUpload" class="row-file">
          <mat-icon class="icon-delete" (click)="deleteFromUploadList(file)">delete</mat-icon>
          <div class="file-name">{{ file.name }}</div>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer class="mat-card-footer-submit">
    <mat-spinner *ngIf="(loadingSubmit$ | async) === true" [diameter]="25"></mat-spinner>
    <button mat-raised-button color="primary" class="button-primary"
            [disabled]="fileForm.invalid || (loadingSubmit$ | async) === true" (click)="submitNewFile()">Absenden
    </button>
    <button mat-button (click)="resetForm()">Abbrechen</button>
  </mat-card-footer>
</mat-card>
