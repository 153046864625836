<div class="block-component-wrapper-no-outbox-selected" *ngIf="outbox === null">
  <div class="block-no-outbox-selected">
    <mat-icon id="mail-icon-no-outbox-selected">mail_outline</mat-icon>
    <span>Wählen Sie eine Nachricht aus</span>
  </div>
</div>
<div *ngIf="outbox" class="block-component-wrapper-outbox-selected">
  <div class="outbox-details-header-wrapper">
    <div class="outbox-details-headerline-action-buttons">
      <button mat-raised-button
              class="button-primary"
              color="primary"
              (click)="answerOutbox()">
        Antworten
      </button>
      <button *ngIf="!allDocumentsAreOnlyPhoneNotes()"
              mat-raised-button
              class="button-primary"
              color="primary"
              (click)="answerOutbox(true)">Signieren/Bestätigen
      </button>
    </div>
    <div class="outbox-details-headerline-pre-subject">
      <span>{{outbox.outboxDetails.commit_date | date:'dd.MM.yyyy - hh:mm'}}</span>
    </div>
    <div class="outbox-details-header">
      <span class="outbox-details-headerline-subject">{{outbox.outboxDetails.subject}}</span>
      <div class="outbox-details-sender-wrapper">
        <span><b>Von:</b></span>
        <div class="outbox-details-sender">
          <span *ngIf="this.displayCommittingEmployeeAsSender ==='1'">
            {{outbox.outboxDetails.committed_by_employee_name}}
          </span>
          <span *ngIf="this.displayCommittingEmployeeAsSender !=='1'">
            {{outbox.outboxDetails.creating_employee_name}}
          </span>
          <mat-icon>person</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="block-outbox-details">

    <div class="header-documents" *ngIf="allDocumentsAreOnlyDocuments();else notAllDocuments">
      Dokument(e) zum Download
    </div>
    <ng-template #notAllDocuments>
      <div class="header-documents">
        Dokumente
      </div>
    </ng-template>

    <mat-card *ngFor="let document of outbox.documents" class="mat-card-document">
      <ng-container *ngIf="documentIsPhoneNote(document) else documentIsNoPhoneNote"
                    [ngClass]="{'curser-pointer': !documentIsPhoneNote(document)}">
        <div class="mat-card-document-flex-left">
          <div class="disabled"
               matTooltip="Keine Datei zum Download verknüpft"
               matTooltipPosition="right">
            <mat-icon class="disabled">attach_file</mat-icon>
          </div>
          <div class="disabled"
               matTooltip="Keine Datei zum Download verknüpft"
               matTooltipPosition="right">
            {{getDocumentTitle(document.description, document.extension)}}
          </div>
        </div>
      </ng-container>

      <ng-template #documentIsNoPhoneNote class="cursor-pointer">
        <div class="mat-card-document-flex-left cursor-pointer">
          <div class="">
            <mat-icon class="cursor-pointer">attach_file</mat-icon>
          </div>
          <div class="">
            {{getDocumentTitle(document.description, document.extension)}}
          </div>
        </div>
        <div *ngIf="hasDocumentNote(document)" class="document-note">
          <i>{{document.note.text | removeBrackets }}</i>
        </div>
        <div class="mat-card-document-flex-right cursor-pointer" (click)="downloadDocument(document)">
          <mat-icon *ngIf="!isDownloading(document)">download</mat-icon>
          <mat-spinner *ngIf="isDownloading(document)" [diameter]="20"></mat-spinner>
        </div>
      </ng-template>

    </mat-card>

  </div>
</div>
