import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatTabGroup} from "@angular/material/tabs";
import {DocumentPageService} from "../../services/document-page.service";


@Component({
  selector: 'app-documents-page',
  templateUrl: './documents-page.component.html',
  styleUrls: ['./documents-page.component.scss']
})
export class DocumentsPageComponent implements AfterViewInit {

  @ViewChild('tabGroup') tabGroup: MatTabGroup | undefined;

  constructor(private documentService: DocumentPageService,) {
  }

  ngAfterViewInit() {
    this.documentService.tabSwitch$.subscribe(index => {
      if (this.tabGroup) {
        this.tabGroup.selectedIndex = index;
      }
    });
  }

}
