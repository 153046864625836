<mat-toolbar>

  <mat-toolbar-row id="toolbar-row-brand">
    <button *ngIf="(isLoggedIn$ | async) === true"
            mat-icon-button
            (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>

    <img (click)="routeToStartPage()" [src]="logo ?? 'assets/favicon.jpg'" class="brand"/>

    <div id="block-header-links">
      <div>
        <a href="" class="custom-link" (click)="openLink(dataProtectionLink); $event.preventDefault()">Datenschutz</a>
      </div>

      <div>
        <a href="" class="custom-link" (click)="openLink(contactLink); $event.preventDefault()">Impressum</a>
      </div>
    </div>
  </mat-toolbar-row>


  <ng-container *ngIf="(isLoggedIn$ | async) === true">
    <mat-toolbar-row id="toolbar-row-menu">
      <mat-nav-list class="mat-nav-list-flex">
        <ng-container *ngIf="userData">
          <a class="link-list-item" routerLink="documents" routerLinkActive="active" mat-list-item
             *ngIf="userData.isEmployee != '1'">Dokumente
            <span id="badge-count-notDownloadedDocuments">{{this.countNotDownloadedDocuments ?? '-'}}</span>
          </a>
          <a class="link-list-item" routerLink="orders" routerLinkActive="active" mat-list-item
             *ngIf="tabOrderActive && userData.isEmployee != '1'">Aufträge</a>
          <a class="link-list-item" routerLink="base-data" routerLinkActive="active" mat-list-item>Persönliche Daten</a>
          <a class="link-list-item" routerLink="user-approval" routerLinkActive="active" mat-list-item
             *ngIf="userData.isEmployee == '1' ||userData.isAdmin == '1' || userData.isSuperAdmin == '1'">Benutzer
            Freigabe</a>
          <a class="link-list-item" routerLink="admin" routerLinkActive="active" mat-list-item
             *ngIf="userData.isAdmin == '1' || userData.isSuperAdmin == '1'">Admin-Bereich</a>
        </ng-container>
        </mat-nav-list>

      <div class="block-userMenu">
        <mat-icon class="icon-account-circle" [matMenuTriggerFor]="userMenu">account_circle</mat-icon>
        <mat-menu #userMenu class="userMenu">
          <ng-container>
            <div class="userMenu-info">
              <mat-icon class="account_circle">
                person
              </mat-icon>
              <span *ngIf="userData" class="mat-menu-username">{{userData.name}} {{userData.surname}}</span>
            </div>
            <mat-divider></mat-divider>
            <mat-list>
              <mat-list-item (click)="logout()" class="primary-color logout-item">
                <mat-icon class="icon-logout">logout</mat-icon>
                <span class="primary-color">Abmelden</span>
              </mat-list-item>
            </mat-list>
          </ng-container>
        </mat-menu>
      </div>

    </mat-toolbar-row>
  </ng-container>


</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <ng-container *ngIf="userData">
      <div class="block-mat-list-menu-mobile">
        <mat-nav-list>
          <a class="link-list-item" routerLink="documents" routerLinkActive="active" mat-list-item>Dokumente</a>
          <a class="link-list-item" routerLink="orders" routerLinkActive="active" mat-list-item
             *ngIf="tabOrderActive">Aufträge</a>
          <a class="link-list-item" routerLink="base-data" routerLinkActive="active" mat-list-item>Meine Daten</a>
          <a class="link-list-item" routerLink="user-approval" routerLinkActive="active" mat-list-item
             *ngIf="userData.isEmployee == '1'">Benutzer Freigabe</a>
          <a class="link-list-item" routerLink="admin" routerLinkActive="active" mat-list-item
             *ngIf="userData.isAdmin == '1' || userData.isSuperAdmin == '1'">Admin-Bereich</a>
          <a class="link-list-item" mat-list-item (click)="logout()">Abmelden</a>
        </mat-nav-list>
      </div>
    </ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content class="block-mat-sidenav-content"></ng-content>
  </mat-sidenav-content>

</mat-sidenav-container>
<a *ngIf="isStartPageActive()" id="block-service-contact"
   href="{{contactLink}}" target="_blank">
  <div class="block-service-contacts-icons">
    <img class="block-service-contacts-icon" src="assets/icons/Icon_Service.svg">
    <img class="block-service-contacts-icon" src="assets/icons/Icon_Contact.svg">
  </div>
  <div class="block-service-contact-title">
    Service & Kontakt
  </div>

</a>
